import { AsyncResult, Cart } from '@msdyn365-commerce/retail-proxy';
import { ICartState } from '@msdyn365-commerce/global-state';
import { IActionContext } from '@msdyn365-commerce/core-internal';
import { GetCatalogIdFromContext } from './get-catalog-id-from-context';

export const GetCartFromCache = (context: IActionContext): Cart | undefined => {
    const cacheKey = _buildCartCacheKey(context);
    const cacheObjectType = 'CART';

    const cartResult = context.get<AsyncResult<ICartState>>(cacheObjectType, cacheKey);

    let cart: Cart | undefined;
    if (cartResult) {
        if (Array.isArray(cartResult)) {
            cart = cartResult[0].result?.cart;
        } else {
            cart = cartResult.result?.cart;
        }
    }

    return cart;
};

const _buildCartCacheKey = (context: IActionContext) => {
    const catalogId = GetCatalogIdFromContext(context);
    const channelId = context.requestContext.channel!.RecordId;
    return `GETCART-CHANNELID:${channelId}-CATALOGID:${catalogId}`;
};

import { IActionContext } from '@msdyn365-commerce/core-internal';

/**
 * Model for saved order template cookies
 */
export interface ISavedOrderTemplate {
    templateName: string;
    templateId: string;
    catalogId: number;
}

/**
 * Class for managing order template cookies
 */
export class OrderTemplateCookieManager {
    /**
     * Action context
     * @private Set in constructor, used for the cookieContext
     */
    private context: IActionContext;

    /**
     * Returns a static cookie name
     */
    private get cookieName(): string {
        return 'order-template';
    }

    /**
     * Gets the cookie context from the action context
     */
    private get cookieContext() {
        return this.context.requestContext.cookies;
    }

    constructor(context: IActionContext) {
        this.context = context;
    }

    /**
     * Checks whether cookie consent is given
     * @private
     */
    private canSetCookie(): boolean {
        return this.cookieContext.isConsentGiven();
    }

    /**
     * Gets order templates from the cookie
     */
    public getOrderTemplates() {
        const cookie = this.cookieContext.get<ISavedOrderTemplate[]>(this.cookieName);
        if (cookie.status === 'FOUND') {
            return cookie.value || [];
        }
        return undefined;
    }

    /**
     * Sets the order template cookie. Can throw an exception if the consent is not set
     * @param savedTemplates saved templates to be set as the cookie
     * @private
     */
    private setOrderTemplateCookie(savedTemplates: ISavedOrderTemplate[]) {
        if (!this.canSetCookie()) {
            throw new Error('Unable to set cookie, must provide consent');
        }
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 1);
        this.cookieContext.set<ISavedOrderTemplate[]>(this.cookieName, savedTemplates, { expires: expiryDate });
    }

    /**
     * Adds an order template to the current session
     * @param templateName name of the template
     * @param templateId id of the template
     * @param catalogId ID of the catalog where the template was created
     */
    public addOrderTemplateToSession(templateName: string, templateId: string, catalogId: number) {
        const savedTemplates: ISavedOrderTemplate[] = this.getOrderTemplates() || [];
        if (savedTemplates) {
            savedTemplates.push({ templateName, templateId, catalogId });
            this.setOrderTemplateCookie(savedTemplates);
        }
    }

    /**
     * Removes the order template cookie
     */
    public clearOrderTemplateCookie() {
        this.cookieContext.remove(this.cookieName);
    }

    /**
     * Removes a single template from the session
     * @param templateId Removes a template from the session
     */
    public removeOrderTemplateFromSession(templateId: string) {
        const savedTemplates = this.getOrderTemplates() || [];
        const idx = savedTemplates.findIndex(st => st.templateId === templateId);
        if (idx > -1) {
            savedTemplates.splice(idx, 1);
        }
        this.setOrderTemplateCookie(savedTemplates);
    }
}
